import analytics from './analytics';
import environment from './environment';
import mocks from './mocks';

const config = {
  analytics,
  environment,
  mocks,
};

export default config;
