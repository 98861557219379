import environment from './environment';

const analytics = {
  posthog: {
    enabled: !environment.isLocal,
    key: process.env.NEXT_PUBLIC_POSTHOG_KEY!,
    host: process.env.NEXT_PUBLIC_POSTHOG_HOST!,
  },
};

export default analytics;
