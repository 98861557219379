'use client';
import { ProgressBar } from '@/components/global-progress';
import { TooltipProvider } from '@/components/ui/tooltip';
import config from '@/config';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { type ReactNode } from 'react';

if (typeof window !== 'undefined' && config.analytics.posthog.enabled) {
  posthog.init(config.analytics.posthog.key, {
    api_host: config.analytics.posthog.host,
  });
}

export default function Providers(props: { children: ReactNode }) {
  return (
    <>
      <PostHogProvider client={posthog}>
        <TooltipProvider>
          <ProgressBar className="fixed top-0 h-0.5 bg-brand-500 shadow-brand-500/50 shadow">
            {props.children}
          </ProgressBar>
        </TooltipProvider>
      </PostHogProvider>
    </>
  );
}
